<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="group_2 flex-col"></div>
      <div class="text-wrapper_1 flex-row">
        <span class="text_1" :class="{active:categoryIndex==1}" @click="handleInfo(1)">未使用（{{num1}}）</span>
        <span class="text_2" :class="{active:categoryIndex==2}" @click="handleInfo(2)">已使用（{{num2}}）</span>
        <span class="text_3" :class="{active:categoryIndex==3}" @click="handleInfo(3)">已失效（{{num3}}）</span>
      </div>

      <div class="group_3 flex-row" v-for="(obj, index) in constants" :key="index">
        <div class="text-group_1 flex-col justify-between">
          <div class="text-wrapper_2">
            <span class="text_4">¥</span>
            <span class="text_5">{{ obj.amount  }}</span>
          </div>
          <!--<span class="text_6">满99可用</span>-->
        </div>
        <div class="text-wrapper_3 flex-col justify-between">
          <span class="text_7">{{ obj.voucherName  }}</span>
          <span class="text_8">{{ obj.createTime.substring(0, 10)  }}&nbsp;~&nbsp;{{ obj.deadline.substring(0, 10)  }}</span>
        </div>
        <!--<div class="text-wrapper_4 flex-col"><span class="text_9">立即使用</span></div>-->
      </div>


    </div>
    <div class="group_5 flex-row">
      
      <div class="box_2 flex-row" @click="onClickLeft">
        <img
          class="label_2"
          referrerpolicy="no-referrer"
          src="./assets/img/33fdd4957ff1c94d7cf88384cdb561d8.png"
        />
        <span class="text_17">我的优惠券</span>
      </div>
    </div>
  </div>
</template>
<script>
  import {queryUserVoucher} from "@/api/user";
export default {
  data() {
    return {
      categoryIndex: 1,
      memberAccount: '',
      constants: [],
      num1:0,
      num2:0,
      num3:0,
      startTime: new Date().getTime(),
      voucherParam:{}
    };
  },
  mounted() {
    this.constants = [];
    const user = localStorage.getItem('userInfoDs')
    this.memberAccount = JSON.parse(user).account
    this.voucherParam.account = this.memberAccount
    queryUserVoucher(this.voucherParam).then(response => {
      if (response.code == 200) {

        response.data.forEach((item) => {
          //1未使用  0 已使用
          if(item.dr == 1){
            this.num1++
            this.constants.push(item)
          }
          if(item.dr == 2){
            this.num2++
          }
          if(this.startTime>new Date(item.deadline.endDate).getTime()){
            this.num3++
          }
        })
      }
    });

  },
  methods: {
    onClickLeft() {
      this.$router.push('/wode')
    },
    handleInfo(type){
      this.categoryIndex = type
      this.constants = [];
      this.voucherParam.account = this.memberAccount
      //1未使用 2已使用 3已失效
      if (type == 1) {
        this.voucherParam.dr = 1
      }
      if (type == 2) {
        this.voucherParam.dr = 2
      }
      if (type == 3) {
        this.voucherParam.dr = 3
      }
      queryUserVoucher(this.voucherParam).then(response => {
        if (response.code == 200) {
          this.constants = response.data
        }
      })
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css"/>


